<template>
  <sign-page
    title-text="党员通讯录"
    :request="request"
    :column-list="columnList"
    :title-menus="[]"
    :table-actions="[]"
    table-size="large">
  </sign-page>
</template>

<script>
import {
  workerRequest,
} from '../../api'

import {
  loadData
} from '../../auth_lib'

export default {
  methods: {
    async loadData (parm) {
      let data = await loadData(workerRequest, this, parm)
      data.forEach(v => {
        v.positionIdText = v.userOrgRoleList.map(v1 => v1.roleId ? v1.orgName + ' ' + v1.roleName : v1.orgName).join('，')
      })
      return data
    }
  },
  computed: {
    columnList () {
      let data = [{
        title: '姓名',
        field: 'name',
        sort: true
      },
      {
        title: '所在组织及职务',
        field: 'positionIdText',
        sort: true
      },
      {
        title: '联系电话',
        field: 'phone'
      }]
      return data
    }
  },
  data () {
    return {
      request: {
        get: this.loadData
      }
    }
  }
}
</script>
